import React, { useEffect, useState } from 'react'

function useResizeWidth() {
  const isWindow = typeof window !== 'undefined'
  const [heightHeader, setHeightHeader] = useState(0)

  const [widthResize, setWidthResize] = useState()
  const [positionY, setPositionY] = useState(0)
  const [isScrollDown, setIsScrollDown] = useState(false)

  let lastScrollTop = window.scrollY

  const isMobile = widthResize <= 500

  const onResizeScreen = () => {
    setWidthResize(window.innerWidth)
  }

  const onScrollPosition = () => {
    setPositionY(window.scrollY)

    const scrollTopPosition =
      window.scrollY || document.documentElement.scrollTop

    if (scrollTopPosition > lastScrollTop) {
      console.log('scrolling down')
      setIsScrollDown(true)
    } else if (scrollTopPosition < lastScrollTop) {
      setIsScrollDown(false)
      console.log('scrolling up')
    }
    lastScrollTop = scrollTopPosition <= 0 ? 0 : scrollTopPosition
  }

  useEffect(() => {
    setWidthResize(window.innerWidth)
    window.addEventListener('resize', onResizeScreen)
    window.addEventListener('scroll', onScrollPosition)
    setHeightHeader(document.querySelector('#header').clientHeight)

    return () => {
      window.removeEventListener('scroll', onScrollPosition)
      window.removeEventListener('resize', onResizeScreen)
    }
  }, [isWindow])

  return { widthResize, positionY, isMobile, heightHeader, isScrollDown }
}

export default useResizeWidth
