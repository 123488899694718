import React from 'react'

import styles from './Foundation.module.scss'
import images from 'assets/images'

const arrContent = [
  {
    title: 'Long-term View and Mindset',
    image: 'longTerm',
    content: `
      Our efforts, which we have already undertaken, will have the
      greatest impact if they result in transformation and growth over
      the <span>long term</span>.
      <br />
      <br />
      Because we are aware that <span>developing</span> a
      <span>blockchain ecosystem</span> that is viable over the long
      term will require time, effort, and the desire to persevere in
      the face of adversity, we view the problem from a long-term
      perspective.
      <br />
      <br />
      Our goal is to <span>create solutions</span> that endure and
      stand the test of time.
    `,
  },
  {
    title: 'Empowerment',
    image: 'empowerment',
    content: `
    The activation of the <span>potential</span> of the TomoChain ecosystem lies at the heart of our purpose. Through participation in educational pursuits, we embrace the responsibility of fostering growth and cultivating a culture of <span>long-term builders</span> who add to the vitality of the environment. 
    <br/>
    <br/>
    In addition, we are constantly on the lookout for potential collaborative collaborations with other organizations, industry players, and academic institutions since we are confident that we will be able to establish a robust and flourishing <span>TomoChain community</span> if we <span>work together</span>. 
    `,
  },
  {
    title: 'Tomo Foundation as The Guiding Light',
    image: 'TomoFoundation',
    classImg: 'img-custom',
    content: `
    Although the Tomo Foundation works to promote the expansion of the TomoChain ecosystem, our goal is not to steer its development in any particular way. 
    <br/>
    <br/>
    Instead, it is our <span>responsibility</span> to act as a source of direction and illumination, throwing light on the ways in which TomoChain might thrive in a manner that is <span>beneficial</span> with the natural potential it possesses. 
    <br/>
    <br/>
    We take a strategy that is both <span>supporting</span> and guiding, in which we enable the ecosystem to determine its own path while providing <span>guidance</span> based on our expertise and observations. 
    `,
  },
  {
    title: 'Collaboration and Inclusivity ',
    image: 'Collaboration',
    classImg: 'img-custom',
    content: `
    We are determined in our conviction that meaningful advancement can only be accomplished via <span>cooperation</span> and the inclusion of all relevant parties.
    <br/>
    <br/>
    The Tomo Foundation fosters a feeling of community by encouraging the active participation of all members of the TomoChain ecosystem. This contributes to the <span>development</span> of a sense of belonging and collective growth. 
    <br/>
    <br/>
    We are able to cultivate an atmosphere that is not just friendly but also inventive and inclusive when we value <span>different points</span> of view and <span>encourage</span> open debate.
    `,
  },
]

function Foundation() {
  const renderContent = () => {
    return arrContent.map((item, i) => {
      return (
        <div className='item-content' key={i}>
          <div className='img-box'>
            <img
              src={images[item.image]}
              alt=''
              className={`img-content ${item.classImg}`}
            />
          </div>

          <div className='content-text'>
            <h6 className='content-text__title'>{item.title}</h6>

            <div
              className='content-text__text'
              dangerouslySetInnerHTML={{ __html: item.content }}
            ></div>
          </div>
        </div>
      )
    })
  }

  return (
    <div className={styles.foundation}>
      <div className='foundation-container'>
        <div className='foundation-head'>
          <div className='foundation-head__content'>
            <h4 className='title-head'>Tomo Foundation's Philosophy</h4>

            <div className='text-description'>
              Our philosophy is fueled by the desire to create a world that
              transcends boundaries, a world where trust is inherent, and
              possibilities are boundless; which is where humanity flourishes.
            </div>
          </div>
        </div>

        <div className='foundation-body'>{renderContent()}</div>
      </div>
    </div>
  )
}

export default Foundation
