import React from 'react'

import styles from './Council.module.scss'
import images from 'assets/images'

const arrCouncil = [
  {
    image: 'council',
    name: 'Le Thanh',
  },
  {
    image: 'council1',
    name: 'Nguyen The Vinh',
  },
  {
    image: 'council2',
    name: 'Long Vuong',
  },
  // {
  //   image: 'council3',
  //   name: 'SON NGUYEN',
  // },
  {
    image: 'council4',
    name: 'Hoang Tung',
  },
]

function Council() {
  const renderCouncil = () => {
    return arrCouncil.map((item, i) => {
      return (
        <div className='item-council' key={i}>
          <img src={images[item.image]} alt='' className='council-img' />

          <div className='council-name'>{item.name}</div>
        </div>
      )
    })
  }

  return (
    <div className={styles.council}>
      <h2 className='council-title'>Meet our Council</h2>

      <div className='council-content'>
        <div className='list-council'>{renderCouncil()}</div>

        <img src={images.bgGridImg} alt='' className='bg-grid' />
      </div>

      <div className='council-description'>
        As the governing body of the Tomo Foundation, we stand at the forefront
        of a global movement, aligning blockchain with <span>human values</span>{' '}
        to empower people and communities worldwide. Our role as the Tomo
        Foundation Council is not just about <span>steering the direction</span>{' '}
        of the foundation; it's about <span>fostering an ecosystem</span> that
        nurtures <span>creativity</span>, fosters
        <span> innovation</span>, and propels <span>meaningful change</span>.
      </div>

      <img src={images.chat} alt='' className='icon-chat' />
    </div>
  )
}

export default Council
