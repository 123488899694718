const images = {
  logoTomo: require('./logoTomo.png'),
  groupBackground: require('./groupBackground.png'),
  empowerment: require('./empowerment.png'),
  longTerm: require('./Longterm.png'),
  TomoFoundation: require('./TomoFoundation.png'),
  Collaboration: require('./Collaboration.png'),
  council: require('./council.png'),
  council1: require('./council1.png'),
  council2: require('./council2.png'),
  council3: require('./council3.png'),
  council4: require('./council4.png'),
  tomoToken: require('./tomoToken.png'),
  tomoToken1: require('./tomoToken1.png'),
  tomoToken2: require('./tomoToken2.png'),
  tomoToken3: require('./tomoToken3.png'),
  chat: require('./chat.png'),
  bgGridContent: require('./bgGridContent.png'),
  bgGridImg: require('./bgGridImg.png'),
}

export default images
