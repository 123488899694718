import Header from 'components/Layout/Header'
import LandingBody from 'pages'

function App() {
  return (
    <div className='App text-base'>
      <Header />

      <div className='body-content'>
        <LandingBody />
      </div>
    </div>
  )
}

export default App
