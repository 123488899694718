import React from 'react'

import styles from './YourIeas.module.scss'
import images from 'assets/images'
import MyButton from 'components/MyButton'
import Footer from 'components/Layout/Footer'

function YourIdeas() {
  return (
    <div className={styles.yourIdeas}>
      <div className='content-wrapper'>
        <div className='content-body'>
          <img src={images.tomoToken} alt='' className='img-token' />

          <div className='text-content'>
            <h1 className='text'>Make your Ideas take off</h1>
            <MyButton
              className='btn-custom'
              url='https://tomochain.com/'
              isBlank
            >
              Build on TomoChain <span className='icon-web_arrow-line'></span>
            </MyButton>
          </div>

          <img
            src={images.tomoToken1}
            alt=''
            className='icon-token icon-token--icon-1'
          />
          <img
            src={images.tomoToken2}
            alt=''
            className='icon-token icon-token--icon-2'
          />
          <img
            src={images.tomoToken3}
            alt=''
            className='icon-token icon-token--icon-3'
          />
        </div>

        <img src={images.bgGridImg} alt='' className='bg-grid' />
      </div>
      <Footer className='footer-custom' />
    </div>
  )
}

export default YourIdeas
