import React from 'react'
import styles from './MyButton.module.scss'
import classNames from 'classnames'

function MyButton({ className, children, type, size, url, isBlank = false }) {
  return (
    <div className={classNames(styles.myButton, className)}>
      {!url ? (
        <button
          className={`button-content button-content--${type} button-content--${size}`}
        >
          {children}
        </button>
      ) : (
        <a
          className={`button-content button-content--${type} button-content--${size}`}
          href={url}
          target={isBlank && '_blank'}
        >
          {children}
        </a>
      )}
    </div>
  )
}

export default MyButton
