import React from 'react'

import styles from './Footer.module.scss'
import images from 'assets/images'
import classNames from 'classnames'

function Footer({ className }) {
  const handleToTop = () => {
    window?.scrollTo({
      top: 0,
    })
  }

  return (
    <div className={classNames(styles.footer, className)}>
      <div className='footer-wrapper'>
        <img
          src={images.logoTomo}
          alt=''
          className='logo'
          onClick={handleToTop}
        />

        <a href='mailto:hi@tomo.foundation'>
          <div className='footer-right'>Contact</div>
        </a>
      </div>
    </div>
  )
}

export default Footer
