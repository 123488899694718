import React, { useEffect, useState } from 'react'

import './Header.scss'
import images from 'assets/images'
import useResizeWidth from 'controller/hooks/useResizeWidth'

function Header() {
  const { isMobile, widthResize, isScrollDown } = useResizeWidth()
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [isExpanse, setIsExpanse] = useState(false)

  const handleToTop = () => {
    window?.scrollTo({
      top: 0,
    })
  }

  useEffect(() => {
    if (widthResize > 768) {
      setIsShowMenu(false)
      setIsExpanse(false)
    }
  }, [widthResize])

  useEffect(() => {
    document.querySelector('body').style.overflowX = 'hidden'

    if (isShowMenu) {
      document.querySelector('body').style.overflowY = 'hidden'
    } else {
      document.querySelector('body').style.overflowY = 'auto'
    }
  }, [isShowMenu])

  return (
    <div
      id='header'
      className={`header header--${isScrollDown ? 'hide' : 'show'}`}
    >
      <div className='header-container'>
        {/* <a href='/'> */}
        <img
          src={images.logoTomo}
          alt=''
          className='icon-logo'
          onClick={handleToTop}
        />
        {/* </a> */}

        {isMobile && (
          <span
            className={`icon-${[
              isShowMenu ? 'web_close' : 'web_menu',
            ]} icon-menu`}
            onClick={() => {
              isMobile && setIsShowMenu(!isShowMenu)
            }}
          ></span>
        )}

        <div className={`header-menu ${isShowMenu && 'header-menu--show'}`}>
          <a
            href='https://frontierdao.link/tomodachi-ambassador-program'
            target='_blank'
            className='menu-item'
            rel='noreferrer'
          >
            Program
          </a>

          <div
            className={`menu-item menu-item--dropdown ${
              isExpanse && 'menu-item--dropdown--expanse'
            }`}
          >
            <div
              className='text-menu'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (widthResize <= 1024) {
                  setIsExpanse(!isExpanse)
                }
              }}
            >
              Community
              <span className='icon-web_arrow-down icon-down'></span>
            </div>
            <div className='menu-child-content'>
              <div className='menu-child'>
                <a
                  href='https://twitter.com/TomoFND'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='menu-child-item'>
                    <span className='icon-web_twitter icon-community'></span>
                    Twitter
                  </div>
                </a>
                <a
                  href='https://discord.gg/vCEJV44knr'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='menu-child-item'>
                    <span className='icon-web_discord icon-community'></span>
                    Discord
                  </div>
                </a>
                <a
                  href='https://t.me/TomoChainChannel'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='menu-child-item'>
                    <span className='icon-web_telegram icon-community'></span>
                    Telegram
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/* <a href='#' className='menu-item'>
            Careers
          </a> */}

          <a
            href='https://blog.tomochain.com/'
            target='_blank'
            className='menu-item'
            rel='noreferrer'
          >
            Blog
          </a>

          <a
            href='mailto:hi@tomo.foundation'
            className='menu-item menu-item--contact'
          >
            Contact
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header
