import React from 'react'

import styles from './LandingBody.module.scss'
import Banner from './Banner'
import useResizeWidth from 'controller/hooks/useResizeWidth'
import Foundation from './Foundation'
import Council from './Council'
import YourIdeas from './YourIdeas'
import images from 'assets/images'

function LandingBody() {
  const { heightHeader } = useResizeWidth()

  return (
    <div className={styles.landingBody}>
      <Banner />

      <div
        className='content-text-star'
        style={{ '--height-header': `${heightHeader}px` }}
      >
        <span className='icon-web_star icon-star'></span>
        <h4 className='text'>
          As we venture into uncharted territory, we recognize that{' '}
          <span>supporting</span> a blockchain requires{' '}
          <span>an innovative approach</span> — one that empowers the ecosystem
          without seeking to control it.
        </h4>
        <img
          src={images.bgGridContent}
          alt=''
          className='bg-grid bg-grid--grid-1'
        />
        <img
          src={images.bgGridContent}
          alt=''
          className='bg-grid bg-grid--grid-2'
        />
      </div>

      <div
        className='content-text-star'
        style={{ '--height-header': `${heightHeader}px` }}
      >
        <span className='icon-web_star icon-star'></span>
        <h4 className='text'>
          We, at Tomo Foundation, embrace this challenge wholeheartedly,{' '}
          <span>learning</span> and <span>adapting</span> each day with the
          mission to foster TomoChain's <span>long-term growth</span> and
          impact.
        </h4>

        <img
          src={images.bgGridContent}
          alt=''
          className='bg-grid bg-grid--grid-3'
        />
        <img
          src={images.bgGridContent}
          alt=''
          className='bg-grid bg-grid--grid-4'
        />
      </div>

      <div
        className='content-text-star'
        style={{ '--height-header': `${heightHeader}px` }}
      >
        <span className='icon-web_star icon-star'></span>
        <h4 className='text'>
          Our teams share a profound commitment to <span>facilitating</span>{' '}
          TomoChain's positive contribution to <span>create</span> an{' '}
          <span>open world</span> where humanity flourishes.
        </h4>

        <img
          src={images.bgGridContent}
          alt=''
          className='bg-grid bg-grid--grid-5'
        />
        <img
          src={images.bgGridContent}
          alt=''
          className='bg-grid bg-grid--grid-6'
        />
      </div>

      <Foundation />

      <Council />

      <YourIdeas />
    </div>
  )
}

export default LandingBody
