import React from 'react'

import styles from './Banner.module.scss'
import MyButton from 'components/MyButton'
import useResizeWidth from 'controller/hooks/useResizeWidth'
import images from 'assets/images'

function Banner() {
  const { heightHeader } = useResizeWidth()

  return (
    <div
      className={styles.banner}
      style={{ '--height-header': `${heightHeader}px` }}
    >
      <h4 className='text-banner'>
        Tomo Foundation is a non-profit organization dedicated to nurturing the
        growth and prosperity of the TomoChain ecosystem.
      </h4>

      <MyButton className='btn-custom' url='https://tomochain.com/' isBlank>
        Learn more about TomoChain{' '}
        <span className='icon-web_arrow-line icon-arrow'></span>
      </MyButton>

      <img src={images.groupBackground} alt='' className='bg-banner' />
    </div>
  )
}

export default Banner
